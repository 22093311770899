<!-- NAME[epic=绩效] 长期未拜访 -->
<template>
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="60px"
    >
      <el-form-item label="区域:" prop="area_id">
        <sale-area-select-tree
          @set-sale-select-id="selectArea"
        ></sale-area-select-tree>
      </el-form-item>
      <el-form-item label="部门:" prop="depart_id">
        <el-select
          v-model="form.depart_id"
          clearable
          style="width: 140px"
          placeholder="请选择部门"
        >
          <el-option
            v-for="(i, idx) in bmList"
            :key="idx"
            :value="i.id"
            :label="i.text"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="业务员:" prop="user_id">
        <el-select
          v-model="form.user_id"
          clearable
          style="width: 140px"
          placeholder="请选择业务员"
        >
          <el-option
            v-for="(i, idx) in staffList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <client-search
          ref="clientSearch"
          :popper-class="'selectDC'"
          @select-id="clientSelect"
        ></client-search>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button
          type="primary"
          @click="
            () => {
              form.pageSize = 10
              form.pageNo = 1
              fetchData()
            }
          "
        >
          查询
        </el-button>
      </el-form-item>
      <!-- <el-form-item label="" prop="">
        <el-button>打印</el-button>
      </el-form-item> -->
      <el-form-item label="" prop="">
        <el-button @click="handlerExport">导出</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button typ="primary" @click="handleRefresh">更新</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { visitList } from '@/api/warning'
  import { departmentList } from '@/api/performance'
  // import { getStaffList } from '@/api/setPrice'
  import SaleAreaSelectTree from '@/baseComponents/saleAreaSelectTree'
  import ClientSearch from '@/baseComponents/clientSearch'
  import { downloadFile, postAction } from '@/api/Employee'
  export default {
    name: 'NotVisit',
    components: { SaleAreaSelectTree, ClientSearch },
    data() {
      return {
        loading: false,
        bmList: [],
        staffList: [],
        list: [],
        checkList: [
          '客户名称',
          '等级',
          '地址',
          '老板姓名',
          '门店老板电话',
          '最后一次拜访时间',
          '拜访人',
          '服务业代',
          '漏店天数',
        ],
        columns: [
          {
            order: 1,
            label: '客户名称',
            prop: 'cust_name',
            width: '',
          },
          {
            order: 1.1,
            label: '等级',
            prop: 'class_name',
            width: '',
          },
          {
            order: 2,
            label: '地址',
            prop: 'address',
            width: '220',
          },
          {
            order: 3,
            label: '老板姓名',
            prop: 'boss',
            width: '',
          },
          {
            order: 4,
            label: '门店老板电话',
            prop: 'mobile',
            width: '',
          },
          {
            order: 5,
            label: '最后一次拜访时间',
            prop: 'last_visit_time',
            width: '',
          },
          {
            order: 6,
            label: '拜访人',
            prop: 'user_name',
            width: '',
          },
          {
            order: 7,
            label: '服务业代',
            prop: 'staff_names',
            width: '',
          },
          {
            order: 9,
            label: '漏店天数',
            prop: 'visit_day',
            width: '',
          },
        ],
        form: {
          pageNo: 1, //当前页
          pageSize: 10, //页条数
          line_id: '', //线路ID
          cust_id: '', //客户名、老板、电话
          area_id: '', //区域
          user_id: '', //业务员ID
          depart_id: '', //部门ID
        },
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {},
    created() {
      this.fetchData()
      departmentList().then((res) => {
        this.bmList = res.data.list
      })
      // 业务员下拉
      postAction('/baseAdmin/common/rel-option').then((r) => {
        this.staffList = r.data
      })
      // getStaffList().then((res) => {
      //   this.staffList = res.data
      // })
    },
    mounted() {},
    methods: {
      async handleRefresh() {
        this.loading = true
        let { data, totalCount } = await postAction(
          '/customerAdmin/warning/cycle-set'
        )
        console.log(data)
        this.$message.success('更新成功')
        this.loading = false
      },
      async fetchData() {
        this.loading = true
        let { data, msg, code, totalCount } = await visitList(this.form)
        console.log(data)
        this.list = data
        this.total = totalCount
        this.loading = false
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      selectArea(val) {
        console.log(val)
        this.form.area_id = val
      },
      clientSelect(val) {
        this.form.cust_id = val
      },
      handlerExport() {
        downloadFile(
          '/customerAdmin/warning/cycle-list-export',
          '长期未拜访.xlsx',
          this.form
        )
      },
    },
  }
</script>
<style lang="scss" scoped></style>
